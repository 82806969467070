var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-text-field',{attrs:{"error-messages":_vm.createdByErrors,"prepend-icon":"mdi-campfire","label":"Dein Fahrtenname (freiwillig)","required":""},model:{value:(_vm.data.createdBy),callback:function ($$v) {_vm.$set(_vm.data, "createdBy", $$v)},expression:"data.createdBy"}},[_c('template',{slot:"append"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v(" "+_vm._s('Gib hier deinen Pfadfindernamen ein.')+" ")])])],1)],2),_c('v-text-field',{attrs:{"error-messages":_vm.createdByEmailErrors,"label":"Deine E-Mail Adresse (freiwillig)","prepend-icon":"mdi-at"},model:{value:(_vm.data.createdByEmail),callback:function ($$v) {_vm.$set(_vm.data, "createdByEmail", $$v)},expression:"data.createdByEmail"}},[_c('template',{slot:"append"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v(" "+_vm._s('Gib hier die E-Mail Adresse ein auf die wir Antworten können.')+" ")])])],1)],2),(_vm.showType)?_c('v-select',{attrs:{"items":_vm.filteredMessageType,"item-value":"id","item-text":"name","error-messages":_vm.messageTypeErrors,"prepend-icon":"mdi-message","label":"Art der Nachricht","required":""},model:{value:(_vm.data.messageType),callback:function ($$v) {_vm.$set(_vm.data, "messageType", $$v)},expression:"data.messageType"}},[_c('template',{slot:"append"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,2874084739)},[_c('span',[_vm._v(" "+_vm._s('Wähle aus wie wir deine Nachricht einsortieren können.')+" ")])])],1)],2):_vm._e(),_c('v-textarea',{attrs:{"label":"Dein Text","required":"","filled":"","auto-grow":"","prepend-icon":"mdi-text","error-messages":_vm.messageBodyErrors,"hint":"Hier deine Nachricht eintippen."},on:{"keyup":_vm.onKeyPress},model:{value:(_vm.data.messageBody),callback:function ($$v) {_vm.$set(_vm.data, "messageBody", $$v)},expression:"data.messageBody"}},[_c('template',{slot:"append"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v(" "+_vm._s('Gib hier den Namen der Schlafstätte ein.')+" ")])])],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }